 
import React from 'react'
import emailjs from 'emailjs-com';
import github from "../images/Github.png";
import twitter from "../images/Twitter.png";
import linkedin from "../images/Linkedin.png";

function Contact() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_dwqkwwl', 'template_vpm6ppe', e.target, 'user_7iXEo5uMDaeyqZ77UdiPu')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <div>
    <div className="container">
      
    <form onSubmit={sendEmail}>
    <h1> You can send me a message! </h1>
    <p> or get through to me via email: tobint99@gmail.com </p>
            <div className="row pt-5 mx-auto">
              
                <div className="col-8 form-group mx-auto">
                    <input type="text" className="form-control" placeholder="Enter your Name" name="name"/>
                </div>
                <div className="col-8 form-group mx-auto">
                    <input type="text" className="form-control" placeholder="Subject " name="subject"/>
                </div>
                <div className="col-8 form-group pt-2 mx-auto">
                    <input type="email" className="form-control" placeholder="Enter your Email Address" name="email"/>
                </div>
                
                <div className="col-8 form-group pt-2 mx-auto">
                    <textarea className="form-control" id="" cols="30" rows="8" placeholder="Your message" name="message"></textarea>
                </div>
                <div className="col-8 pt-3 mx-auto">
                    <input type="submit" className="btn btn-info" value="Send Message"></input>
                </div>
            </div>
        </form>
        <div className = "about-text2">
          <hr></hr>
        <p> or.... via social media? </p>
          
        <div className="about-text2-icons">
          <a target="blank" href="https://github.com/richiricardo">
            <img src={github} alt="GitHub" />
          </a>
          <a target="blank" href="https://www.linkedin.com/in/tobin-thankachan-209a00175 ">
            <img src={linkedin} alt="LinkedIn" />
          </a>
          <a target="blank" href="https://twitter.com/codingwithak">
            <img src={twitter} alt="Twitter" />
          </a>
          </div>
        </div>
    </div>
</div>
)
}

export default Contact;