import React from "react";
import Typical from "react-typical";
import github from "../images/Github.png";
import cv from "../images/cv.png";
import linkedin from "../images/Linkedin.png";
import cvw from "../images/cvw.png";

function Home() {
  return (
    <div className="home-container">
      <div className="home-text">
        <h1>
          <Typical wrapper="b" steps={["Hi, I'm Tobin Thankachan", 2500]} />
        </h1>

        <div className="home-text">
          <p>
            <Typical
              wrapper="b"
              steps={["Computer Science Graduate 💻", 3000]}
            />
          </p>
          <div className="about-text2">
            <div className="about-text2-icons">
              <a target="blank" href="https://github.com/richiricardo">
                <img src={github} alt="GitHub" />
              </a>
              <a target="blank" href={cvw}>
                <img src={cv} alt="cv" />
              </a>
              <a
                target="blank"
                href="https://www.linkedin.com/in/tobin-thankachan-209a00175 "
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
