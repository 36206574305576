import React from "react";
import tobin from "../images/Myself.jpg";
import js from "../images/JavaScript.png";
import html from "../images/HTML.png";
import css from "../images/CSS.png";
import react from "../images/React.png";
import github from "../images/Github.png";
import python from "../images/python.png";


function About() {
  return (
    <div className="about">
      <div className="about-header">
        <h1> About Me </h1>
      </div>
      <div className="about-image">
        <img src={tobin} alt="Tobin Thankachan" />
      </div>
      <div className="about-text">
        <p>
          I was very keen on technology from young as i wasn't exposed to any
          until I came to the UK. This interest grew into more of a passion over
          time which lead me to get into programming to start developing my own
          websites and automate daily tasks around me.
        </p>
        <p>
          Due to my growing interest in programming, I had opted to do Computer
          Science at University which had not only exposed me to basic elements
          of programming but also the business elements of the industry.
        </p>
        <p>
          Furthermore, due to the current covid outbreak, I like to keep myself
          busy with side projects based on my surroundings and I find this an
          effective way to keep my coding skills in check!
        </p>
        <p>
          I also enjoy good leisure time which could be playing sports, in the
          gym or just hanging with friends.
        </p>
       
    </div>
    <div className="about-text2">
       <p>I have used and gained experience in the following: </p>
        <div className="about-text2-icons">
          <a href="#">
            <img src={github} alt="GitHub" />
          </a>
          <a href="#">
            <img src={python} alt="Python" />
          </a>
          <a href="#">
            <img src={html} alt="HTML" />
          </a>
          <a href="#">
            <img src={css} alt="CSS" />
          </a>
        
          <a href="#">
            <img src={js} alt="JS" />
          </a>
          <a href="#">
            <img src={react} alt="React" />
          </a>
        
          
        </div>
      </div>
    </div>

  );
}

export default About;
