import React from "react";
import amazonclone from "../images/amazonclone.jpg";
import signalclone from "../images/signalclone.png";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function Portfolio() {
  return (
    <div className="portfolio">
      <div className="portfolio-text">
        <h1> Projects </h1>
      </div>
      <>
        <CardDeck>
          <Card bg="info">
            <Card.Header>Amazon Clone (Fullstack)</Card.Header>
            <Card.Img variant="top" src={amazonclone} />
            <Card.Body>
              <Card.Text>
                This is a full-stack web application project of attempting
                replicate the Amazon webpage with the use of the React
                framework. There are various functions the users are able to do
                within this website. The users are able to create an account,
                log in, order items online along with adding and removing
                products to the basket. This website also uses the Stripe API in
                order to allow real-time payment process.{" "}
              </Card.Text>
              <div className="portfolio-buttons">
                <Button
                  href="https://clone-project-4638e.web.app/"
                  variant="dark"
                >
                  Live Website
                </Button>{" "}
                <Button
                  href="https://github.com/richiricardo/amazon-clone-project"
                  variant="dark"
                >
                  Code
                </Button>{" "}
              </div>
            </Card.Body>
          </Card>

          <Card bg="info">
            <Card.Header>Signal Clone</Card.Header>
            <Card.Img variant="top" src={signalclone} />
            <Card.Body>
              <Card.Text>
                This is a web/mobile application created which minorly imitates
                the new Signal messaging app. This is an expo build using react
                native which gives the user the ability to login, register,
                create groupchats and communicate between other accounts within
                the chat.
              </Card.Text>
              <div className="portfolio-buttons">
                <Button href="https://interact-e54d0.web.app" variant="dark">
                  Live Website
                </Button>{" "}
                <Button
                  href="https://github.com/richiricardo/signal-clone-apps"
                  variant="dark"
                >
                  Code
                </Button>{" "}
              </div>
            </Card.Body>
          </Card>
        </CardDeck>
      </>
    </div>
  );
}

export default Portfolio;
